import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';

import Lottie from 'react-lottie';
import animation from './assets/img/LoadingLogo.json';

const About = lazy(() => import('./components/About'));
const Home = lazy(() => import('./components/Home'));
const DataSources = lazy(() => import('./components/DataSources'));
const Navbar = lazy(() => import('./components/Navbar/Navbar'));

const App: React.FC = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const Main = withRouter(({ location }) => {
    return (
      <div>
        {location.pathname !== '/' && <Navbar />}
        <Switch>
          <Route exact path="/" component={About} />
          <Route exact path="/map" component={Home} />
          <Route exact path="/map/:city" component={Home} />
          <Route exact path="/data-sources" component={DataSources} />
        </Switch>
      </div>
    );
  });

  return (
    <div className="App">
      <Suspense
        fallback={<Lottie options={defaultOptions} height={267} width={150} />}
      >
        <Router>
          <Main />
        </Router>
      </Suspense>
    </div>
  );
};

export default App;
