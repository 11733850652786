import {
  configureStore,
  createSlice,
  getDefaultMiddleware,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  Metros,
  MetroArea,
  SelectedCensusTract,
  InitialStateMetro,
  InitialStateCovid,
  SelectedPropertyType,
} from '../types';
import metro from '../assets/data/metros.json';

// reorder the GeoLocation variable in the metros.json data to be used in map.flyTo function in Map.tsx
const newMetro: Metros = metro;
export const newerMetro = Object.keys(newMetro).map(
  (key: string) => newMetro[key],
);
newerMetro.forEach((met: MetroArea) => {
  met.GeoLocation.reverse();
});

// creating intitial state objects to be used in our slices
const covidInitialState: InitialStateCovid = {
  Data: [],
  loading: 'idle',
  selectedProperty: {
    text: 'Urban Health Vulnerability Index',
    key: 'mvi',
  },
};
const metroInitialState: InitialStateMetro = {
  Data: [],
  selectedMetro: [
    {
      MetroID: 61,
      CityName: 'Albuquerque, NM',
      StateAbbr: 'NM',
      GeoLocation: [-106.647447696, 35.1055025771],
      MetroName: 'Albuquerque/Rio Rancho',
      BoundingBox: {
        SW: { longitude: -107.197244, latitude: 34.871018 },
        NE: { longitude: -106.413953, latitude: 35.219459 },
      },
      MetroCentroid: [35.1613241186, -106.6876282745],
    },
  ],
  hospitalVis: true,
  nursingVis: false,
  currentSelection: null,
};

// slices that creates our actions based on our reducers
const metroSlice = createSlice({
  name: 'metroData',
  initialState: metroInitialState,
  reducers: {
    getMetroData: (state, { payload }: PayloadAction<MetroArea[]>): void => {
      state.Data = payload;
    },
    setMetroArea: (
      state,
      { payload }: PayloadAction<MetroArea | null>,
    ): void => {
      if (payload !== null) {
        state.selectedMetro!.push(payload);
      }
      if (state.selectedMetro!.length >= 3) {
        state.selectedMetro!.splice(0, 1);
      }
    },
    setHospitalVis: (state, { payload }: PayloadAction<boolean>): void => {
      state.hospitalVis = payload;
    },
    setNursingVis: (state, { payload }: PayloadAction<boolean>): void => {
      state.nursingVis = payload;
    },
    setCurrentSelection: (
      state,
      { payload }: PayloadAction<SelectedCensusTract>,
    ): void => {
      state.currentSelection = payload;
    },
  },
});
const covidSlice = createSlice({
  name: 'covidData',
  initialState: covidInitialState,
  reducers: {
    setSelectedProperty: (
      state,
      { payload }: PayloadAction<SelectedPropertyType>,
    ): void => {
      state.selectedProperty = payload;
    },
  },
});

// combining the reducers
const reducer = {
  CovidData: covidSlice.reducer,
  MetroData: metroSlice.reducer,
};

// export the actions that was created from our slices
export const {
  setMetroArea: selectMetroAction,
  getMetroData: getMetroAction,
  setHospitalVis: hospitalVisAction,
  setNursingVis: nursingVisAction,
  setCurrentSelection: currentSelectionAction,
} = metroSlice.actions;

export const { setSelectedProperty: selectProperty } = covidSlice.actions;

// initialize store with our combined reducers and middleware then exports.
export const store = configureStore({
  reducer,
  middleware: [...getDefaultMiddleware({ serializableCheck: false })],
});

// dispatching data that should set to our store on load
// store.dispatch(getCovidData('/states/current.json'));
store.dispatch(getMetroAction(newerMetro));
